<template>
	<div></div>
</template>
<script>
import _ from 'lodash';
import {
	deleteSnsLoginCookies,
	getOAuthResultCookie,
	getInviteCodeCookie,
	getDaouofficeCodeCookie,
	removeInviteCodeCookie,
	removeDaouofficeCodeCookie,
} from '@/utils/cookies.js';
import mixinsAlert from '@/components/common/mixinsAlert';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';

import { SNS_ALREADY_SIGNUP_TRY_ANOTHER, SNS_ALREADY_SIGNUP_TRY_LOGIN } from '@/temlplates';

export default {
	mixins: [mixinsAlert, mixinLinkAccount],
	created() {
		const result = getOAuthResultCookie();
		deleteSnsLoginCookies();
		this.setStoreLinkCodesAndClearCookie();

		//TODO: 스테이징 확인을 위한 로그
		console.log('Resource Server 에서 넘어온 SNS 데이터: ', result);
		if (!_.isEmpty(result.email) || result.isLinkedAccount == null) {
			// 회원가입
			this.$router.push({
				name: 'Signup',
				params: {
					snsEmail: result.email,
					snsProfileUrl: result.profile,
					snsName: result.name,
					snsId: result.snsId,
					snsType: result.snsType,
					snsCi: result.snsCi,
				},
			});
		} else {
			this.isLogin(result);
		}
	},
	methods: {
		isLogin(result) {
			const isLinkedAccount = result.isLinkedAccount;
			const isMainSnsId = result.isMainSnsId;
			this.$router.replace('/login');

			if (!isLinkedAccount || !isMainSnsId) {
				this.alert({
					title: '이미 가입한 사용자입니다.',
					contents: SNS_ALREADY_SIGNUP_TRY_ANOTHER,
					buttons: [
						{
							text: '로그인',
							callback: () => {
								this.$router.replace('/login');
							},
						},
					],
				});
			} else if (isLinkedAccount && isMainSnsId) {
				this.alert({
					contents: SNS_ALREADY_SIGNUP_TRY_LOGIN,
					buttons: [
						{
							callback: () => {
								const BEARER_TYPE = 'Bearer ';
								sessionStorage.setItem('authToken', BEARER_TYPE + result.accessToken);
								if (result.accessToken) {
									this.linkService().finally(() => this.$router.replace('/home'));
								}
							},
						},
						{
							callback: () => {
								this.$router.replace('/signup');
							},
						},
					],
				});
			}
		},
		setStoreLinkCodesAndClearCookie() {
			const inviteCodeCookie = getInviteCodeCookie();
			const daouofficeCodeCookie = getDaouofficeCodeCookie();
			if (inviteCodeCookie) {
				this.$store.commit('SET_INVITE_CODE', inviteCodeCookie);
			}
			if (daouofficeCodeCookie) {
				this.$store.commit('SET_DAOUOFFICE_CODE', daouofficeCodeCookie);
			}
			removeInviteCodeCookie();
			removeDaouofficeCodeCookie();
		},
	},
};
</script>

<style></style>
